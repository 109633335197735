module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1044026376379392"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MiaBuysHouses.com","short_name":"MiaBuysHouses","start_url":"/","background_color":"#ff2ecc","theme_color":"#ff2ecc","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"eb9cbd317df30b1703de8ec84263ca45"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
